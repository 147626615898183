// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-biostatistics-js": () => import("./../../../src/pages/biostatistics.js" /* webpackChunkName: "component---src-pages-biostatistics-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-data-management-blueprint-js": () => import("./../../../src/pages/data-management-blueprint.js" /* webpackChunkName: "component---src-pages-data-management-blueprint-js" */),
  "component---src-pages-data-management-js": () => import("./../../../src/pages/data-management.js" /* webpackChunkName: "component---src-pages-data-management-js" */),
  "component---src-pages-data-management-oversight-js": () => import("./../../../src/pages/data-management-oversight.js" /* webpackChunkName: "component---src-pages-data-management-oversight-js" */),
  "component---src-pages-free-guides-js": () => import("./../../../src/pages/free-guides.js" /* webpackChunkName: "component---src-pages-free-guides-js" */),
  "component---src-pages-hang-on-js": () => import("./../../../src/pages/hang-on.js" /* webpackChunkName: "component---src-pages-hang-on-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-meeting-js": () => import("./../../../src/pages/meeting.js" /* webpackChunkName: "component---src-pages-meeting-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-quality-assurance-js": () => import("./../../../src/pages/quality-assurance.js" /* webpackChunkName: "component---src-pages-quality-assurance-js" */),
  "component---src-pages-references-js": () => import("./../../../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-start-here-js": () => import("./../../../src/pages/StartHere.js" /* webpackChunkName: "component---src-pages-start-here-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-book-page-template-js": () => import("./../../../src/templates/bookPageTemplate.js" /* webpackChunkName: "component---src-templates-book-page-template-js" */)
}

